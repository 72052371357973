.projects {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
}

.projectTitle {
  text-align: center;
  color: black;
  font-size: 60px;
  font-weight: bolder;
}

.projectList {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  gap: 40px; /* Space between project items */
  align-items: center;
}

.projectItem {
  border-radius: 15px;
  width: 100%;
  max-width: 400px; /* Limit the maximum width of each project item */
  height: auto;
  margin: 20px;
  text-align: center;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in;
}

.projectItem:hover {
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.projectImage {
  width: 100%;
  height: auto; /* Maintain the aspect ratio */
  border: 2px solid #ddd;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for aesthetics */
}

.projectDescription {
  margin-top: 10px;
  font-size: 16px;
}

@media only screen and (max-width: 1300px) {
  .projectList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .projectList {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .projectItem {
    width: 300px;
    height: 300px;
  }
}



/* .projects {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .projects .projectTitle {
    text-align: center;
    color: #3e497a;
    font-size: 60px;
    font-weight: bolder;
  }
  
  .projectList {
    width: 70vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
  }

  
  .projectItem {
    border-radius: 15px;
    width: 300px;
    height: 300px;
    margin: 40px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  .projectItem:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
  }
  
  .projectItem .bgImage {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .projectItem h1 {
    font-size: 25px;
  }
  
  @media only screen and (max-width: 1300px) {
    .projectList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      grid-template-columns: 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      width: 100%;
    }
    .projectItem {
      width: 300px;
      height: 300px;
    }
  } */

  /* .projects {
    text-align: center;
    padding: 20px;
  }
  
  .projectList {
    display: flex;
    flex-direction: column; 
    align-items: center;
    gap: 40px; 
  }
  
  .projectItem {
    width: 100%;
    max-width: 400px;
    text-align: center;
    margin: 20px;
  }
  
  .projectImage {
    width: 100%;
    height: auto; 
    border: 2px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  }
  
  .projectDescription {
    margin-top: 10px;
    font-size: 16px;
  } */
  
  